/* Global styles go here.
 * Please refrain from editing this file as much as possible.
 * Angular should be component focused by design. Most styles should be in component scss files.
 * Global overrides such as "style resets" are perfect for here.
 */
@use '@garmin-avcloud/avcloud-ui-common/style-variables/styles';
@use '@garmin-avcloud/avcloud-ui-common/style-variables/global-defaults';
@use '@garmin-avcloud/avcloud-ui-common/style-variables/theme-variables' as theme;
@use '@garmin-avcloud/avcloud-ui-common/style-variables/scss/components-common/scrollbar';

@use '@angular/cdk/overlay-prebuilt.css';

.trust-arc-cookie-preferences {
  a {
    color: var(theme.$text-primary);

    &:hover {
      color: var(theme.$text-secondary);
    }
  }
}

.modal-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(theme.$ui-curtain);
}
